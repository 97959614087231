import { Tabs } from "@mantine/core";
import { useState } from "react";
import { PageLayout } from "../../components/layout";
import { ScfFinanceLimitList } from "../../components/limit/scf-list";

export const LimitUsageList = () => {
  const breadcrumbs = useBreadcrumb();
  const [filters, setFilters] = useState<any>({ financeType: "LBF" });

  return (
    <PageLayout title="Лимит ашиглалт" subTitle="Лимит ашиглалтын жагсаалт" breadcrumb={breadcrumbs}>
      <Tabs
        onTabChange={(e) => {
          setFilters({
            ...filters,
            financeType: e,
          });
        }}
        value={filters.financeType}
        color={"golomt"}>
        <Tabs.List>
          <Tabs.Tab value={"LBF"} py="md">
            Санхүүжилт
          </Tabs.Tab>
          <Tabs.Tab value={"SCF"} py="md">
            Факторинг
          </Tabs.Tab>
        </Tabs.List>
        <ScfFinanceLimitList filters={filters} />
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Лимит ашиглалт",
  },
];
