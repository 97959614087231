import { Collapse, Tabs, Text } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BuyerMasterContractList } from "../../components/finance-program/buyer-master-contract-list";
import { BuyerSubcontractList } from "../../components/finance-program/buyer-subcontract-list";
import { SupplierMasterContractList } from "../../components/finance-program/supplier-master-contract-list";
import { SupplierSubcontractList } from "../../components/finance-program/supplier-subcontract-list";
import { PageLayout } from "../../components/layout";
import { IAuth } from "../../interfaces/IAuth";

export const FinanceProgram = () => {
  const breadcrumbs = useBreadcrumb();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [tabKey, setTabKey] = useState<string | "LBF" | "SCF">("LBF");

  return (
    <PageLayout
      title={
        <div>
          <Collapse in={tabKey === "LBF"} transitionDuration={400}>
            <Text>Зээлийн шугамаар</Text>
          </Collapse>
          <Collapse in={tabKey !== "LBF"} transitionDuration={400}>
            <Text>Нэхэмжлэх барьцаалах</Text>
          </Collapse>
        </div>
      }
      subTitle={
        user?.currentBusiness?.type === "SUPPLIER" ? (
          <div>
            <Collapse in={tabKey === "LBF"} transitionDuration={400}>
              <span>Зээлийн шугам ашиглаж хамрагдах боломжтой хөтөлбөрүүд</span>
            </Collapse>
            <Collapse in={tabKey !== "LBF"} transitionDuration={400}>
              <span>Өөрийн найдвартай худалдан авагчдаа батлан дааж “Мастер гэрээ”-ний дагуу “эрт санхүүжилт” авах боломжтой хөтөлбөрүүд</span>
            </Collapse>
          </div>
        ) : (
          <div>
            <Collapse in={tabKey === "LBF"}>
              <span>Бэлтгэн нийлүүлэгчийн баталгаанд үндэслэн “Зээлийн шугамаар” санхүүжилт авах хөтөлбөр</span>
            </Collapse>
            <Collapse in={tabKey !== "LBF"}>
              <span>Өөрийн ханган нийлүүлэгчдийн найдвартай харилцааг баталгаажуулж “Мастер гэрээ” хийсэн хөтөлбөр</span>
            </Collapse>
          </div>
        )
      }
      breadcrumb={breadcrumbs}>
      <Tabs onTabChange={(e) => setTabKey(e || "LBF")} value={tabKey} defaultValue={tabKey}>
        <Tabs.List>
          <Tabs.Tab value="LBF" py="md">
            Санхүүжилт
          </Tabs.Tab>
          <Tabs.Tab value="SCF" py="md">
            Факторинг
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="LBF">
          {tabKey === "LBF" && <div>{user?.currentBusiness?.type === "SUPPLIER" ? <BuyerSubcontractList /> : <SupplierSubcontractList />}</div>}
        </Tabs.Panel>
        <Tabs.Panel value="SCF">
          {tabKey === "SCF" && <div>{user?.currentBusiness?.type === "SUPPLIER" ? <SupplierMasterContractList /> : <BuyerMasterContractList />}</div>}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Санхүүжилтийн хөтөлбөр",
  },
];
